#legendButton {
    bottom: 0.5em;
    left: 0.5em;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfMAAAFmCAMAAABOeoOJAAAAdVBMVEUAAABGRkZRUVFJSUlKSkpNTU1MTExNTU1LS0tLS0tMTExLS0tLS0tMTExKSkpMTExLS0tLS0tKSkpMTExKSkpKSkpLS0tMTExMTExLS0tLS0tMTExLS0tLS0tLS0tLS0tLS0tLS0tMTExLS0tLS0tLS0v////97Y+dAAAAJXRSTlMACxMVGCsvMjNHV1xfYWRsdHd4eXx/hIeUoKSzxczP0uXn6fv8fW0dwQAAAAFiS0dEJloImLUAAAJwSURBVHja7d1PSwJBHMfh36xjiWBRh64dev+vKjqE4B9KyNjVbt30NOwM7vNchRG+H3b0tullGYW9D1c+fOuCita7iNzNSx+bLNs2j53maI7maI7maI7maI7maI7maI7maI7maK45mqM5mqM5mqM5mqM5mqM5mqM5mqM5mmuO5miO5miO5miO5miO5miO5miO5miO5pqjOZqjOZqjOZqjOZqjOZqjOZqjOZqjueZojuZojuZojuZojuZojuZojuZojuZorjlTkvd96SOHax9+JpvX9GMCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIB/KeJuUfrQ75NhG5YjVs+lD/31NoCWeR/LJJ/zkT3dW72mr8P4zVea1/0Hd3C3+z1HczRHczRHczRHczRHczRHczRHczRHc83RHM3RHM3RHM3RHM3RHM3RHM3RHM3RXHM0R3M0R3M0R3M0R3M0R3M0R3M0R3M01xzN0RzN0RzN0RzN0RzN0RzN0RzN0RzNNUdzNEdzNEdzNEdzNEdzNEdzNEdzLssRp3PpQ892bbz5dmsGdzu3/pyPrHfzV9WbAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAmIz0Oit84vHDqm3LXenmyaitNx//Kxdzs9c0VGj++GD3mnrvV5sezTVHczRHczRHczRHczRHczRHczRHczRHc83RHM3RHM3RHM3RHM3RHM3RHM3RHM3RXHM0R3M0R3M0R3M0R3M0R3M0R3M0R3M01xzN0RzN0RzN0RzN0RzN0RzN0RzN0RzNNUdzNEdzNEdzNEdzNEdzNEdzNEdzLsubWeETj0ZtvfneBu52bt0f1scg5ClXzRsAAAAASUVORK5CYII=);
    background-size: cover;
}

#legendButton:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfMAAAFmCAMAAABOeoOJAAAAdVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD///9551tKAAAAJXRSTlMACxMVGCsvMjNHV1xfYWRsdHd4eXx/hIeUoKSzxczP0uXn6fv8fW0dwQAAAAFiS0dEJloImLUAAAJwSURBVHja7d1PSwJBHMfh36xjiWBRh64dev+vKjqE4B9KyNjVbt30NOwM7vNchRG+H3b0tullGYW9D1c+fOuCita7iNzNSx+bLNs2j53maI7maI7maI7maI7maI7maI7maI7maK45mqM5mqM5mqM5mqM5mqM5mqM5mqM5mmuO5miO5miO5miO5miO5miO5miO5miO5pqjOZqjOZqjOZqjOZqjOZqjOZqjOZqjueZojuZojuZojuZojuZojuZojuZojuZorjlTkvd96SOHax9+JpvX9GMCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIB/KeJuUfrQ75NhG5YjVs+lD/31NoCWeR/LJJ/zkT3dW72mr8P4zVea1/0Hd3C3+z1HczRHczRHczRHczRHczRHczRHczRHc83RHM3RHM3RHM3RHM3RHM3RHM3RHM3RXHM0R3M0R3M0R3M0R3M0R3M0R3M0R3M01xzN0RzN0RzN0RzN0RzN0RzN0RzN0RzNNUdzNEdzNEdzNEdzNEdzNEdzNEdzLssRp3PpQ892bbz5dmsGdzu3/pyPrHfzV9WbAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAmIz0Oit84vHDqm3LXenmyaitNx//Kxdzs9c0VGj++GD3mnrvV5sezTVHczRHczRHczRHczRHczRHczRHczRHc83RHM3RHM3RHM3RHM3RHM3RHM3RHM3RXHM0R3M0R3M0R3M0R3M0R3M0R3M0R3M01xzN0RzN0RzN0RzN0RzN0RzN0RzN0RzNNUdzNEdzNEdzNEdzNEdzNEdzNEdzLsubWeETj0ZtvfneBu52bt0f1scg5ClXzRsAAAAASUVORK5CYII=);
    background-size: cover;
}

#legend {
    position: absolute;
    bottom: 0.7em;
    left: 0.7em;
    width: 45%;
    height: 35%;
    z-index: 50;
    display: none;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    border-radius: 5px;
    /* justify-content: center; */
}

#legendClose {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.1em;
    color: #000000;
    background-color: rgba(255, 255, 255, 0.7);
    font-family: quicksand, "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    padding-right: 1%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 10%;
}

#legendClose:hover {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 4 4 4 4;
}

.legendSubContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    /*  background: rgba(128, 128, 179, 0.7); */
    width: 50%;
    height: 100%;
}

.legendSubSubContainer {
    /* width: 50%; */
    height: 95%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: flex-end;
}

.legendColorRamp {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.colorAndLabel {
    width: 100%;
    height: 12%;
    display: flex;
    align-items: flex-end;
}

.legendColorLabel {
    width: 50%;
    height: 100%;
    text-align: center;
    font-size: 75%;
    word-break: break-all;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 5%;
}

.legendColorRampLabels {
    width: 50%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#legendShapes {
    width: 64%;
}

.squareContainer {
    width: 55% !important;
}

#circleLabels {
    width: 36%;
}

.circleOneLabel {
    width: 42%;
    font-size: 78%;
    word-break: break-all;
    text-align: left;
    margin: auto !important;
}

.circleTwoLabels {
    width: 36%;
    display: flex;
    flex-direction: column;
    word-break: break-allk;
}

#legendCircleContainer {
    width: 100%;
    height: 70%;
    display: flex;
}

.subContainerLabel {
    position: absolute;
    top: 17%;
    height: 10%;
    max-height: 10%;
    width: 24%;
    text-align: center;
    font-size: 0.9rem;
    overflow: hidden;
}

.bigSubSubContainer {
    width: 55%;
}

.smallSubSubContainer {
    width: 45%;
    margin-left: 4%;
}

.legendLinkSquares {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.zoomOutMessage {
    font-size: 0.7em;
    margin: auto;
    width: 90%;
}

chart {
    display: inline-block;
    height: 151px;
    margin-bottom: 20px;
}

.reset {
    padding-left: 1em;
    font-size: smaller;
    color: #ccc;
}

.background.bar {
    fill: #ccc;
}

.foreground.bar {
    fill: steelblue;
}

.brush-handle {
    fill: #eee;
    stroke: #666;
}